import { findLuchthaven } from '@taxiboeken-v2/utilities-berekening'

/**
 * Formateer adressen naar een zoekterm
 */

export default adressenDTO => {
  const adressen = (!adressenDTO || typeof adressenDTO === 'string' ? [] : adressenDTO)
  return adressen
    .map(el => {
      const adres = (el.adres || el)
      const luchthaven = findLuchthaven(el)
      if (luchthaven?.name) {
        return `Luchthaven ${luchthaven.name} ${adres}`
      }
      return adres
    })
    .join(' ')
}
