<template>
  <div v-if="modelValue" class="card flex flex-col md:flex-row items-center md:items-start w-full gap-2">
    <div class="px-6 md:w-40 text-center md:text-left flex flex-row md:flex-col gap-2 items-center md:items-start justify-center">
      <span class="order-2 md:order-1 font-bold block"><i class="fas fa-calendar mr-1"></i> Afspraak</span>
      <span class="order-1 md:order-2 label-time inline-block text-sm">{{ modelValue.tijd ? timeString(`2022-12-12 ${modelValue.tijd}`) : 'Hele dag' }}</span>
    </div>
    <div class="md:pl-8 md:pr-2 flex-1 flex flex-col md:flex-row md:items-start gap-4 w-full">
      <div class="flex flex-col flex-1 gap-2">
        <strong v-if="!compact" class="block card gray pr-12 w-full">
          {{ modelValue.titel }}
        </strong>
        <GoogleAdressenVisueel v-if="modelValue.adressen?.length" class="card gray pr-32" :adressen="modelValue.adressen" />
      </div>
      <div class="flex flex-1 flex-col gap-2 items-start">
        <p v-if="!hideChauffeur" class="block card gray w-full">
          <span class="font-bold">Chauffeur:</span>
          {{ store.getters.chauffeur(modelValue.user_id)?.label }}
        </p>
        <ButtonAlertOpmerking v-if="!compact" :opmerking="modelValue.opmerking" preview-length="30" />
      </div>
      <div v-show="!compact" v-if="!hideActions && store.getters.hasKantoorPermission" class="flex flex-col gap-1 -my-1">
        <router-link
          class="btn bg-yellow-400 text-white h-8"
          :to="`/afspraken/bewerken/${modelValue.id}`"
        >
          <i class="fas fa-edit"></i> Bewerken
        </router-link>
        <button v-if="store.getters.hasMasterPermission" class="btn error" @click="onHandleVerwijder(modelValue)">
          <i class="fas fa-trash text-sm"></i> Verwijderen
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

import { timeString } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useModal from '@/hooks/useModal'

defineProps({
  modelValue: Object,
  compact: Boolean,
  hideActions: Boolean,
  hideChauffeur: Boolean,
})
const emit = defineEmits(['refresh'])
const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))
const GoogleAdressenVisueel = defineAsyncComponent(() => import('@/components/Google/AdressenVisueel.vue'))

const api = useApi()
const store = useStore()
const [openFixedModal] = useModal({ fixed: true })

const onHandleVerwijder = afspraak => {
  const callback = () => {
    api('AFSPRAAK_DELETE', { id: afspraak.id }).then(() => {
      emit('refresh', true)
    })
  }
  openFixedModal('BEVESTIG_ACTIE', { message: 'Afspraak verwijderen?', callback })
}
</script>
