<template>
  <div class="w-full">
    <div class="card bg-white mb-1 lg:sticky lg:top-0 gap-0" style="width:inherit">
      <div class="flex flex-col lg:flex-row gap-4 items-start">
        <h2 class="whitespace-nowrap w-full h-auto no-border">
          Kalender &mdash; {{ capitalize(dateWritten(date)) }}
        </h2>

        <div class="mt-1 text-center justify-end">
          <UIInputDatePicker v-model:date="date" buttons />
        </div>

        <div class="flex flex-col sm:flex-row lg:flex-col lg:justify-center lg:justify-start items-end gap-2 w-full">
          <div class="inline-flex flex-row items-center gap-2">
            <button v-if="store.getters.hasAdminPermission" class="btn indigo small" @click="() => toggleTab(tabs.WERKDRUK)">
              <i class="fas fa-clock"></i>
            </button>
            <router-link
              v-if="store.getters.hasAdminPermission"
              target="_blank"
              :to="{ name: 'RittenRendabiliteit', query: { date } }"
              class="btn bg-teal-400 text-white"
            >
              <i class="fas fa-money-check-alt"></i>
            </router-link>
            <router-link
              v-if="store.getters.hasAdminPermission"
              target="_blank"
              :to="{ name: 'RittenVRP', query: { date } }"
              class="btn bg-orange-400 text-white"
            >
              <i class="fas fa-route text-base"></i>
            </router-link>
            <router-link
              v-if="store.getters.hasAdminPermission"
              target="_blank"
              :to="{ name: 'PrintContracten', query: { date } }"
              class="btn purple"
            >
              <i class="fas fa-file-contract text-base"></i>
            </router-link>
            <button class="btn green small" @click="handleRefreshButton">
              <i class="fas fa-sync"></i>
            </button>
            <button v-if="store.getters.hasAdminPermission" class="btn yellow small" @click="showGeocodeStatus = !showGeocodeStatus">
              <i class="fas fa-globe-americas"></i>
            </button>
            <UIButtonCompact v-if="store.getters.hasAdminPermission" v-model="compact" class="small" />
          </div>

          <UICheckbox v-model="annulatiesTonen" class="whitespace-nowrap" :label="`(${geannuleerdeRitten?.length}) Annulaties tonen`" />
        </div>
      </div>

      <div class="flex flex-col lg:flex-row mt-4 lg:-mt-4" style="width: calc(100% - 200px)">
        <ErrorBoundery>
          <UILoading v-if="loadingAfwezigheden" sm />
          <UITableKalenderAfwezigheden v-else :afwezigheden="afwezigheden?.list || []" />
        </ErrorBoundery>
      </div>
    </div>

    <div v-if="tab === tabs.WERKDRUK" class="card w-full">
      <TableWerkdruk :kalender="bevestigdeRitten" />
    </div>

    <template v-else>
      <div v-if="store.getters.hasAdminPermission" class="card w-full mb-1 lg:pt-24">
        <div class="grid grid-cols-2 lg:grid-cols-4 gap-2 py-2">
          <UIFormElement label="Chauffeur">
            <SelectChauffeur
              v-model="filters.user_id"
              nullable
              :datum="date"
              :prefix-items="[{ label: 'Niet toegekend', value: -1 }]"
            />
          </UIFormElement>
          <UIFormElement label="Wagen">
            <SelectWagen v-model="filters.wagen_id" nullable active />
          </UIFormElement>
          <UISelectFixed
            v-model="filters.options"
            multiple
            type="KALENDER_FILTERS"
            placeholder="Alles tonen"
          />
          <UIFormElement label="Zoeken">
            <input
              v-model="filters.search"
              type="text"
              placeholder="Zoeken..."
              @input="filters.search = $event?.target?.value || ''"
            >
          </UIFormElement>
        </div>
      </div>

      <small v-if="store.getters.hasAdminPermission && !loadingItems && !compact" class="text-xs font-normal flex flex-col md:items-center card mb-2">
        <span class="flex flex-col md:flex-row md:gap-2">
          <span class="whitespace-nowrap"><b>Afspraken</b>: {{ afspraken?.list?.length || 0 }}</span>
          <span class="hidden md:inline">&mdash;</span>
          <span class="whitespace-nowrap"><b>Ritten</b>: {{ waardeRittenAantal }}</span>
          <span class="hidden md:inline">&mdash;</span>
          <span class="whitespace-nowrap"><b>Dagwaarde</b>: {{ formatPrice(waardeDagTotaal + waardeAnnulatieKostenDagTotaal) }} EUR</span>
          <span class="hidden md:inline">&mdash;</span>
          <span class="whitespace-nowrap"><b>Annulatiekosten</b>: {{ formatPrice(waardeAnnulatieKostenDagTotaal) }} EUR</span>
          <span class="hidden md:inline">&mdash;</span>
          <span class="whitespace-nowrap"><b>Kilometers</b>: {{ formatPrice(waardeKilometersTotaal) }}</span>
        </span>
      </small>

      <div class="card w-full mb-1 relative overflow-x-auto">
        <small v-if="loadingItems" class="block text-center py-4">Dag {{ date }} aan het laden...</small>
        <ErrorBoundery>
          <UILoading v-if="loadingItems" sm />
          <TableKalender
            v-else
            :compact="compact"
            :show-geocode-status="showGeocodeStatus"
            :annulaties-tonen="annulatiesTonen"
            :reservaties="kalenderFiltered"
            :afspraken="afsprakenFiltered"
            @date="date = $event"
            @refresh-kalender="refreshKalender"
            @refresh-afspraken="refreshAfspraken"
          />
        </ErrorBoundery>
      </div>

      <div>
        <UIButtonNieuweRitToevoegen class="fixed bottom-5 right-5" :date="date" />
        <UIButtonNieuweAfspraakToevoegen class="fixed bottom-16 right-5" :date="date" />
      </div>
    </template>

    <!--
    <details>
      <summary>KALENDER</summary>
      <pre>{{ kalender }}</pre>
    </details>
    <details>
      <summary>AFSPRAKEN</summary>
      <pre>{{ afspraken }}</pre>
    </details>
    <details>
      <summary>AFWEZIGHEDEN</summary>
      <pre>{{ afwezigheden }}</pre>
    </details>
    <details>
      <summary>WERKDAGENNAELKAAR</summary>
      <pre>{{ werkdagenNaElkaar }}</pre>
    </details>
    -->
  </div>
</template>

<script setup>
import { computed, onMounted, provide, ref, watch } from 'vue'
import { useStore } from 'vuex'

import ErrorBoundery from '@/components/ErrorBoundery.vue'
import UITableKalenderAfwezigheden from '@/components/Table/Kalender/Afwezigheden.vue'
import TableKalender from '@/components/Table/Kalender/Index.vue'
import TableWerkdruk from '@/components/Table/Werkdruk/Index.vue'
import UIButtonCompact from '@/components/UI/Button/Compact.vue'
import UIButtonNieuweAfspraakToevoegen from '@/components/UI/Button/NieuweAfspraakToevoegen.vue'
import UIButtonNieuweRitToevoegen from '@/components/UI/Button/NieuweRitToevoegen.vue'
import UIFormElement from '@/components/UI/Form/Element.vue'
import UICheckbox from '@/components/UI/Input/Checkbox.vue'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UILoading from '@/components/UI/Loading.vue'
import SelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import SelectWagen from '@/components/UI/Select/Wagen.vue'

import { dateWritten } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import { capitalize, lowercase } from '@/functions/formatText'
import useAdressenSearchTerm from '@/hooks/useAdressenSearchTerm'
import useGetApi from '@/hooks/useGetApi'
import useRouteQueryDate from '@/hooks/useRouteQueryDate'

const store = useStore()
const date = useRouteQueryDate('date')

const compact = ref(false)
const showGeocodeStatus = ref(false)
const annulatiesTonen = ref(false)
const filters = ref({
  user_id: null,
  wagen_id: null,
  is_factuur_nodig: null,
  options: [],
  search: '',
})

const tabs = {
  KALENDER: 'KALENDER',
  WERKDRUK: 'WERKDRUK',
}
const tab = ref(tabs.KALENDER)
const toggleTab = newTab => {
  tab.value = tab.value === newTab ? tabs.KALENDER : newTab
}

const apiData = computed(() => ({ all: true, date: date.value }))
const apiDataAfwezigheden = computed(() => ({
  year: new Date(date.value).getFullYear(),
  month: new Date(date.value).getMonth() + 1,
  day: new Date(date.value).getDate(),
  status: 'ACCEPTED',
  roundDay: true,
}))
const apiDataWerkdagenNaElkaar = computed(() => ({
  date: date.value,
}))
const apiDataPrestaties = computed(() => ({
  date: date.value,
}))

const { data: kalender, getData: refreshKalender, loading: loadingKalender } = useGetApi('/api/dashboard/kalender/list', apiData, { watch: false })

const { data: afspraken, getData: refreshAfspraken, loading: loadingAfspraken } = useGetApi('AFSPRAAK_LIST', apiData, { watch: false })

const { data: afwezigheden, getData: refreshAfwezigheden, loading: loadingAfwezigheden } = useGetApi('KALENDER_AFWEZIGHEID_LIST', apiDataAfwezigheden, { watch: false })

const { data: werkdagenNaElkaar, getData: refreshWerkdagenNaElkaar, loading: loadingWerkdagenNaElkaar } = useGetApi('KALENDER_WERKDAGEN_NA_ELKAAR_LIST', apiDataWerkdagenNaElkaar, { watch: false })

const { data: prestaties, getData: refreshPrestaties, loading: loadingPrestaties } = useGetApi('PRESTATIES_KALENDER', apiDataPrestaties, { watch: false })

const loadingItems = computed(() => loadingKalender.value || loadingAfspraken.value || loadingWerkdagenNaElkaar.value || loadingPrestaties.value)

const handleRefreshButton = () => {
  refreshKalender()
  refreshAfspraken()
  refreshAfwezigheden()
  refreshWerkdagenNaElkaar()
  refreshPrestaties()
}

onMounted(() => {
  watch([apiData, apiDataAfwezigheden, apiDataWerkdagenNaElkaar, apiDataPrestaties], () => {
    refreshKalender()
    refreshAfspraken()
    refreshAfwezigheden()
    refreshWerkdagenNaElkaar()
    refreshPrestaties()
  }, { immediate: true })
})

const isAfspraakToShow = item => {
  if (!item) return false

  if (filters.value.user_id == -1 && item.user_id > 0) {
    return false
  } else if (filters.value.user_id > 0 && item.user_id !== filters.value.user_id) {
    return false
  }

  for (const filterOptie of filters.value.options) {
    if (typeof filterOptie?.filterFn !== 'function') continue
    const shouldShow = filterOptie?.filterFn(item)
    if (!shouldShow) return false
  }

  if (filters.value.search) {
    const term = lowercase(filters.value.search).trim()
    return (
      lowercase(item.titel).includes(term)
      || lowercase(item.opmerking || '').includes(term)
      || lowercase(useAdressenSearchTerm(item.adressen)).includes(term)
    )
  }

  return true
}

const afsprakenFiltered = computed(() => {
  return (afspraken.value?.list || []).filter(item => isAfspraakToShow(item))
})

const isKalenderToShow = item => {
  if (!item) return false

  if (filters.value.user_id == -1 && !item.chauffeurs.find(x => !x.chauffeur_id)) {
    return false
  } else if (filters.value.user_id > 0 && !item.chauffeurs.find(x => x.chauffeur_id === filters.value.user_id)) {
    return false
  }

  if (filters.value.wagen_id && !item.chauffeurs.find(x => x.wagen_id === filters.value.wagen_id)) {
    return false
  }

  for (const filterOptie of filters.value.options) {
    if (typeof filterOptie?.filterFn !== 'function') continue
    const shouldShow = filterOptie?.filterFn(item)
    if (!shouldShow) return false
  }

  if (filters.value.search) {
    const term = lowercase(filters.value.search).trim()
    if (
      lowercase(item.klant?.firstname).includes(term)
      || lowercase(item.klant?.lastname).includes(term)
      || lowercase(item.klant?.phone).includes(term)
      || lowercase(item.klant?.email).includes(term)
      || lowercase(item.reiziger?.firstname).includes(term)
      || lowercase(item.reiziger?.lastname).includes(term)
      || lowercase(item.reiziger?.phone).includes(term)
      || lowercase(item.reiziger?.email).includes(term)
    ) {
      return true
    }

    const termWithoutSpaces = term.replace(/\s+/g, '')
    return (
      lowercase(item.rit?.id) === termWithoutSpaces
      || lowercase(item.rit?.vlucht).replace(/\s+/g, '').includes(termWithoutSpaces)
      || lowercase(item.rit?.promotie_code).replace(/\s+/g, '').includes(termWithoutSpaces)
      || lowercase(item.rit?.opmerking).includes(term)
      || lowercase(useAdressenSearchTerm(item.adressen)).includes(term)
    )
  }

  return true
}

const kalenderFiltered = computed(() => {
  return (kalender.value?.list || []).map(item => {
    if (!item.chauffeurs?.length) {
      item.chauffeurs = [{ chauffeur_id: null, wagen_id: null }]
    }
    return item
  }).filter(item => isKalenderToShow(item))
})

const bevestigdeRitten = computed(() => (kalender.value?.list || []).filter(el => el?.rit?.is_bevestigd))
const geannuleerdeRitten = computed(() => (kalender.value?.list || []).filter(el => el?.rit?.is_geannuleerd))
const waardeRittenAantal = computed(() => bevestigdeRitten.value.length)
const waardeDagTotaal = computed(() => bevestigdeRitten.value.reduce((total, next) => total + (next?.rit?.prijs || 0) / (next?.rit?.is_retour ? 2 : 1), 0))
const waardeAnnulatieKostenDagTotaal = computed(() => geannuleerdeRitten.value.reduce((total, next) => total + (next?.rit?.kosten_annulatie || 0) / (next?.rit?.kosten_annulatie ? 2 : 1), 0))
const waardeKilometersTotaal = computed(() => bevestigdeRitten.value.reduce((total, next) => total + next?.rit?.kilometers || 0, 0))

provide('kalender', {
  kalenderFiltered,
  afwezigheden,
  werkdagenNaElkaar,
  prestaties,
  bevestigdeRitten,
  geannuleerdeRitten,
  waardeRittenAantal,
  waardeDagTotaal,
  waardeAnnulatieKostenDagTotaal,
  waardeKilometersTotaal,
})
</script>
