<template>
  <ul class="flex flex-row flex-wrap gap-1 whitespace-nowrap items-center pr-2">
    <li><span class="font-bold text-sm">Afwezigheden ({{ sortedAfwezigheden?.length || 0 }}):</span></li>
    <li
      v-for="item in sortedAfwezigheden"
      :key="item.id"
      class="text-xs bg-orange-50 border border-solid border-orange-300 rounded px-1 inline-flex gap-1"
      @click="alertAfwezigheid(item.SOURCE)"
    >
      <i :class="icon(item.SOURCE)"></i>
      {{ store.getters.chauffeur(item.user_id)?.label }} <span class="italic">({{ timeString(item.start) }} - {{ timeString(item.einde) }})</span>
    </li>
    <li v-if="!sortedAfwezigheden?.length">
      Geen afwezigheden
    </li>
  </ul>
</template>

<script setup>
import { computed, defineProps, toValue } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import { timeString } from '@/functions/formatDate'

const props = defineProps({
  afwezigheden: Array,
})

const sortedAfwezigheden = computed(() => {
  const afwezigheden = toValue(props.afwezigheden || []) || []
  return clone(afwezigheden)
    .sort((a, b) => {
      const aChauffeur = store.getters.chauffeur(a.user_id)?.label || ''
      const bChauffeur = store.getters.chauffeur(b.user_id)?.label || ''

      if (aChauffeur.startsWith('[INACTIEF]')) return 1
      if (bChauffeur.startsWith('[INACTIEF]')) return -1

      const aStart = new Date(a.start)
      const bStart = new Date(b.start)

      if (aStart.getHours() !== bStart.getHours() || aStart.getMinutes() !== bStart.getMinutes()) {
        return aStart.getTime() - bStart.getTime()
      }

      return aChauffeur.localeCompare(bChauffeur)
    })
})

const store = useStore()

const icon = SOURCE => {
  if (SOURCE === 'AANWEZIGHEID') return 'fas fa-user-plus text-teal-500'
  if (SOURCE === 'WERKROOSTER') return 'fas fa-calendar-alt text-purple-500'
  // if (source === 'AFWEZIGHEID')
  return 'fas fa-user-minus text-red-500'
}

const alertAfwezigheid = SOURCE => {
  if (SOURCE === 'AANWEZIGHEID') return alert(`Aanwezigheid aangevraagd en goedgekeurd, dit zijn de uren waar hij NIET beschibaar is. (${SOURCE})`)
  if (SOURCE === 'WERKROOSTER') return alert(`Dit is een afwezigheid op basis van werkrooster/werkdagen. (${SOURCE})`)
  // if (source === 'AFWEZIGHEID')
  return alert(`Afwezigheid aangevraagd en goedgekeurd. (${SOURCE})`)
}
</script>
