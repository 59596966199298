export const colorForUserId = seed => {
  let randomColor = `#${Math.floor(Math.abs(Math.sin(seed) * 13224393)).toString(16)}`

  if (randomColor.length == 5) { // In any case, the color code is invalid
    randomColor += 'C6'
  } else if (randomColor.length == 6) { // In any case, the color code is invalid
    randomColor += '9'
  } else if (randomColor.length > 7) { // In any case, the color code is invalid
    randomColor = randomColor.substring(0, 7)
  }

  return randomColor.toUpperCase()
}
