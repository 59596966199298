<template>
  <ErrorBoundery>
    <div :key="datum" class="flex flex-col gap-2">
      <div
        v-for="(c, chauffeurIndex) in list"
        :key="`${list[chauffeurIndex].id}${list[chauffeurIndex].chauffeur_id}${chauffeurIndex}`"
        class="flex flex-row"
      >
        <div class="flex-1 flex flex-col">
          <div class="inline-flex gap-1 items-center mr-1">
            <button
              v-if="store.getters.hasMasterPermission"
              class="btn warning xs"
              @click="onHandleCustomEdit(chauffeurIndex)"
            >
              <i class="fas fa-edit text-sm"></i>
            </button>
            <SelectChauffeur
              v-model="list[chauffeurIndex].chauffeur_id"
              class="inline-block text-lg w-auto flex-1"
              placeholder="Chauffeur..."
              :format-chauffeur="formatChauffeur"
              :datum="datum"
              disable-afwezig
              active
              :disabled="disabled"
              skip-mounted
              @update:model-value="(chauffeur_id) => { setTouched(true); onSelectChauffeur(list[chauffeurIndex], chauffeur_id) }"
            />
            <SelectWagen
              v-if="false && store.getters.hasAdminPermission"
              v-model="list[chauffeurIndex].wagen_id"
              class="inline-block text-lg"
              placeholder="Wagen..."
              :disabled="disabled"
              active
              @update:model-value="() => setTouched(true)"
            />
            <span
              class="border py- px-2 text-lg rounded font-bold bg-white flex flex-row items-center justify-center gap-2"
            >
              <span>{{ chauffeurWerkdagenText(list[chauffeurIndex].chauffeur_id) }}</span>
              <span>{{ chauffeurPrestatieText(list[chauffeurIndex].chauffeur_id) }}</span>
            </span>
          </div>
          <div class="flex flex-row gap-4">
            <LabelShiftUren
              v-if="store.getters.hasKantoorPermission"
              compact
              alerts
              kalender
              :show-all="store.getters.hasAdminPermission"
              :start="list[chauffeurIndex]?.shift_start || null"
              :aangekomen="list[chauffeurIndex]?.shift_aangekomen || null"
              :klant_ingestapt="list[chauffeurIndex]?.shift_klant_ingestapt || null"
              :klant_afgezet="list[chauffeurIndex]?.shift_klant_afgezet || null"
              :einde="list[chauffeurIndex]?.shift_einde || null"
              :is-no-show="list[chauffeurIndex]?.shift_is_no_show || false"
              :wagen_id="list[chauffeurIndex]?.shift_wagen_id || null"
              :chiron_status="list[chauffeurIndex]?.shift_chiron_status || null"
              class="-mt-0.25"
            />
          </div>
        </div>
        <div class="col-span-2 flex flex-col items-start gap-2 mt-1.5">
          <div v-if="!disabled" class="inline-flex gap-2 items-end justify-end">
            <button
              class="btn error w-6 h-6 inline-flex justify-center items-center"
              @click="removeChauffeur(chauffeurIndex)"
            >
              -
            </button>
            <button
              v-if="chauffeurIndex === 0"
              class="btn green w-6 h-6 inline-flex justify-center items-center"
              @click="addChauffeurs(1)"
            >
              +
            </button>
          </div>

          <button
            v-if="store.getters.hasMasterPermission && isChiron"
            class="btn pt-0.5 purple xs"
            @click="onHandleChironReservatieSturen(chauffeurIndex)"
          >
            Chiron
          </button>
        </div>
      </div>
    </div>
  </ErrorBoundery>
</template>

<script setup>
import {
  computed,
  defineAsyncComponent,
  defineEmits,
  defineProps,
  inject,
  onMounted,
  toValue,
} from 'vue'
import { useStore } from 'vuex'
import { useMounted, useVModel } from '@vueuse/core'

import ErrorBoundery from '@/components/ErrorBoundery.vue'

import { millisecondsToHMSWritten } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useModal from '@/hooks/useModal'

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  klant_id: {
    type: Number,
    default: null,
  },
  rit_id: {
    type: Number,
    required: true,
  },
  isRetour: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  minimum: {
    type: Number,
    required: true,
  },
  blacklist: {
    type: Array,
    default() {
      return []
    },
  },
  datum: [Date, String, Number],
  isTeOntvangen: Boolean,
  isChiron: Boolean,
})

const emit = defineEmits(['touched', 'update:modelValue'])

const LabelShiftUren = defineAsyncComponent(() => import('@/components/UI/Labels/ShiftUren.vue'))

const SelectChauffeur = defineAsyncComponent(() =>
  import('@/components/UI/Select/Chauffeur.vue'),
)
const SelectWagen = defineAsyncComponent(() =>
  import('@/components/UI/Select/Wagen.vue'),
)

const [openFixedModal] = useModal({ fixed: true })
const api = useApi()
const store = useStore()
const isMounted = useMounted()
const list = useVModel(props, 'modelValue', emit, {
  deep: true,
  passive: true,
})

const getEmptyChauffeur = () => ({
  chauffeur_id: null,
  is_bus: false,
  is_retour: props.isRetour,
  rit_id: props.rit_id,
  wagen_id: null,
})

const setTouched = (value = true) => {
  emit('touched', value)
}

onMounted(() => {
  const minimum = Math.max(1, props.minimum || 0)
  const aantal = minimum - (list.value?.length || 0)
  if (!list.value?.length) {
    list.value = []
  }
  if (aantal > 0) {
    for (let i = 1; i <= aantal; i += 1) {
      list.value.push(getEmptyChauffeur())
    }
  }
})

const addChauffeurs = (n = 1) => {
  if (!list.value?.length) {
    list.value = []
  }
  for (let i = 1; i <= n; i += 1) {
    list.value.push(getEmptyChauffeur())
  }
  setTouched()
}

const removeChauffeur = chauffeurIndex => {
  list.value.splice(chauffeurIndex, 1)
  if (!list.value.length) {
    addChauffeurs(1)
    return // Return because touch by addChauffeur
  }
  setTouched()
}

const onSelectChauffeur = item => {
  if (!isMounted) return

  setTouched()

  // Standaard wagen voor chauffeur invullen indien van toepassing
  if (!item.chauffeur_id) return
  const wagen_id = store.getters.chauffeur(item.chauffeur_id)?.wagen_id
  if (wagen_id) {
    item.wagen_id = wagen_id
  }
}

const onHandleChironReservatieSturen = chauffeurIndex => {
  const record = list.value[chauffeurIndex]

  const message = 'Chiron reservatie doorsturen?'
  const callback = () => api('CHIRON_RESERVATIE', {
    planning_id: record.planning_id,
    chauffeur_id: record.chauffeur_id,
  }).then(() => {
    list.value[chauffeurIndex].shift_chiron_status = 'RESERVATIE'
  })

  openFixedModal('BEVESTIG_ACTIE', { message, callback })
}

const onHandleCustomEdit = chauffeurIndex => {
  const record = list.value[chauffeurIndex]
  openFixedModal('FORM_TOEKENNING', {
    modelValue: {
      id: record.id,
      shift_id: record.shift_id,
      planning_id: record.planning_id,
      user_id: record.chauffeur_id,
      wagen_id: record.shift_wagen_id,
      start: record.shift_start,
      aangekomen: record.shift_aangekomen,
      klant_ingestapt: record.shift_klant_ingestapt,
      klant_afgezet: record.shift_klant_afgezet,
      einde: record.shift_einde,
      is_no_show: record.shift_is_no_show,
      wachttijd: record.shift_wachttijd,
      chiron_status: record.shift_chiron_status,
    },
    callback: updated => {
      list.value[chauffeurIndex].chauffeur_id = updated.user_id
      list.value[chauffeurIndex].shift_wagen_id = updated.wagen_id
      list.value[chauffeurIndex].shift_start = updated.start
      list.value[chauffeurIndex].shift_aangekomen = updated.aangekomen
      list.value[chauffeurIndex].shift_klant_ingestapt = updated.klant_ingestapt
      list.value[chauffeurIndex].shift_klant_afgezet = updated.klant_afgezet
      list.value[chauffeurIndex].shift_einde = updated.einde
      list.value[chauffeurIndex].shift_is_no_show = updated.is_no_show
      list.value[chauffeurIndex].shift_wachttijd = updated.wachttijd
    },
  })
}

/**
 * Mappers & formatters
 */
const { kalenderFiltered, werkdagenNaElkaar, prestaties } = inject('kalender')

const emojiNumbers = {
  0: '0️⃣',
  1: '1️⃣',
  2: '2️⃣',
  3: '3️⃣',
  4: '4️⃣',
  5: '5️⃣',
  6: '6️⃣',
  7: '7️⃣',
  8: '8️⃣',
  9: '9️⃣',
  10: '🔟',
}

const emojiCircle = {
  RED: '🔴',
  YELLOW: '🟡',
  ORANGE: '🟠',
  GREEN: '🟢',
}

const chauffeurWerkdagenText = computed(() => chauffeur_id => {
  const list = toValue(werkdagenNaElkaar)?.list || []
  const kalender = toValue(kalenderFiltered) || []

  let aantal = 0
  let status = emojiCircle.GREEN
  if (list?.length && chauffeur_id > 0) {
    aantal = list.find(x => x.user_id === chauffeur_id)?.aantal || 0
    if (aantal > 3) status = emojiCircle.YELLOW
    if (aantal > 5) status = emojiCircle.ORANGE
    if (aantal > 7) status = emojiCircle.RED

    const ergensAangeduidVandaag = kalender?.findIndex(record => record.chauffeurs.find(item => item.chauffeur_id == chauffeur_id)) > -1
    if (ergensAangeduidVandaag) {
      aantal += 1
    }

    aantal = Math.min(10, Math.max(0, aantal))
  }

  return `${status}${emojiNumbers[aantal]}`.trim()
})

const chauffeurPrestatieText = computed(() => chauffeur_id => {
  const list = toValue(prestaties) || {}
  let tijd = 0
  if (chauffeur_id > 0) {
    tijd = list[chauffeur_id]?.met || 0
  }
  return `🕓 ${millisecondsToHMSWritten(tijd)}`.trim()
})

const formatChauffeurBlacklist = chauffeur => {
  const found = (props.blacklist || []).find(user_id => user_id === chauffeur.value)
  if (found) {
    // chauffeur.disabled = true
    chauffeur.label = `👎 ${chauffeur.label || ''}`
    if (props.klant_id > 0) {
      chauffeur.link = `/opvolging/enquetes?user_id=${chauffeur.value}&klant_id=${props.klant_id}`
    }
  }

  return chauffeur
}

const formatChauffeur = chauffeur => {
  return formatChauffeurBlacklist(chauffeur)
}
</script>
