<template>
  <button v-if="store.getters.hasMasterPermission" class="icon" @click="handleClick">
    <i
      :class="{
        'fas fa-thumbs-up text-blue-500': isPossible,
        'fas fa-ban text-red-500': !isPossible,
      }"
    ></i>
  </button>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import useModal from '@/hooks/useModal'

const props = defineProps({
  kalenderRecord: { type: Object, required: true },
})

const emit = defineEmits(['submitted'])

const store = useStore()

const [openFixedModal] = useModal({ fixed: true })

const errors = computed(() => {
  const errors = []

  if (!store.getters.hasMasterPermission || !(props.kalenderRecord?.rit?.id > 0))
    return errors

  const rit = props.kalenderRecord.rit

  if (rit.is_retour) {
    const d1 = new Date(rit.datum || rit.vertrek_datetime)
    const d2 = new Date(rit.datum_linked || rit.retour_datetime)

    if (d1.getFullYear() !== d2.getFullYear())
      errors.push('Verschillende jaren heen/retour')

    if (d1.getMonth() !== d2.getMonth())
      errors.push('Verschillende maanden heen/retour')
  }

  if (rit.bedrag_ontvangen < rit.prijs || rit.bedrag_open_saldo > 0)
    errors.push('Rit nog niet volledig betaald')
  else if (!rit.is_enkel_cash_betaald)
    errors.push('Niet enkel met cash betaald')

  if (rit.is_chiron) errors.push('Chiron')
  if (rit.is_online_recensie) errors.push('Online review/enquete/score ingevuld/gegeven')
  if (rit.is_factuur_nodig || rit.facturatie_id) errors.push('Op factuur')
  if (rit.is_offerte || rit.is_offerte_ingevuld) errors.push('Via offerte')
  if (rit.is_betalingsbewijs) errors.push('Betaalbewijs')
  if (rit.bedrag_terugbetaald > 0) errors.push('Terugbetaling uitgevoerd')
  if (rit.voucher_gegeven) errors.push('Voucher gegeven')
  if (rit.promotie_code) errors.push('Promotie gebruikt')

  return errors
})

const isPossible = computed(() => errors.value.length === 0)

const callback = () => emit('submitted', true)
const handleClick = () => {
  if (!isPossible.value) {
    alert(errors.value.join('\n'))
    return
  }
  openFixedModal('FORM_RIT_X', {
    id: props.kalenderRecord.rit.id,
    callback,
  })
}
</script>
